<template>
  <router-view />
</template>

<style lang="scss">
$background-color: #151314;
$primary-color: #f1be70;
$secondary-color: #e7ceb0;
$accent-color: #e07534;

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
* {
  font-family: "Source Sans Pro", sans-serif;
}

html,
body,
#app {
  background-color: $background-color;
  color: $secondary-color;
  margin: 0;
  padding: 0;
}

::selection {
  background: $accent-color;
  color: $secondary-color;

  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: white;
}
</style>
