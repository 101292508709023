
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  methods: {
    async disableCookies() {
      await localStorage.removeItem("cookiesAccepted");
      // Delete all cookies
      await document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });
      location.reload();
    },
  },
});
