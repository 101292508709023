
import { defineComponent } from "vue";

export default defineComponent({
  name: "CookiesBanner",
  data() {
    return {
      cookiesAccepted: true,
    };
  },
  mounted() {
    if (localStorage.getItem("cookiesAccepted") === null) {
      this.cookiesAccepted = false;
    }
  },
  methods: {
    accepted() {
      localStorage.setItem("cookiesAccepted", "true");
      document.getElementById("cookies-base")!.style.display = "none";
    },
    declined() {
      localStorage.setItem("cookiesAccepted", "false");
      document.getElementById("cookies-base")!.style.display = "none";
    },
  },
});
