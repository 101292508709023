
import { defineComponent } from "vue";
import confetti from "canvas-confetti";

export default defineComponent({
  name: "EmailSignup",
  methods: {
    createConfetti(elementId: string) {
      const button = document.getElementById(elementId)!;
      const rect = button.getBoundingClientRect();
      const width = rect.right - rect.left;
      const height = rect.bottom - rect.top;
      const x = rect.left + width / 2;
      const y = rect.top + height / 2;

      // Confetti
      confetti({
        particleCount: 100,
        startVelocity: 20,
        spread: 360,
        origin: {
          x: x / window.innerWidth,
          y: y / window.innerHeight,
        },
      });
    },
  },
});
