
import { defineComponent } from "vue";

export default defineComponent({
  name: "SocialsIntegration",
  mounted() {
    if (localStorage.getItem("cookiesAccepted") === "true") {
      this.cookiesAccepted = true;
    }
    const checkCookies = setInterval(() => {
      if (localStorage.getItem("cookiesAccepted") === "true") {
        this.cookiesAccepted = true;
        clearInterval(checkCookies);
      }
    }, 250);
  },
  data() {
    return {
      cookiesAccepted: false,
    };
  },
  methods: {
    accepted() {
      localStorage.setItem("cookiesAccepted", "true");
      if (document.getElementById("cookies-base") !== null)
        document.getElementById("cookies-base")!.style.display = "none";
    },
  },
});
