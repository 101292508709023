
import { defineComponent } from "vue";
export default defineComponent({
  name: "MainHeader",
  mounted() {
    // When the user scrolls down 20px from the top of the document, slide down the navbar
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        document.getElementById("header-base")?.classList.remove("header-show");
        document.getElementById("header-base")?.classList.add("header-hide");
      } else {
        if (
          document
            .getElementById("header-base")
            ?.classList.contains("header-hide")
        ) {
          document.getElementById("header-base")?.classList.add("header-show");
        }
        document.getElementById("header-base")?.classList.remove("header-hide");
      }
    }
  },
});
