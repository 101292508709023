
import { defineComponent } from "vue";
import axios from "axios";

if (window.location.hostname === "badtoadgames.com") {
  axios.defaults.baseURL = "https://badtoadgames.com/api";
} else {
  axios.defaults.baseURL = "http://192.168.10.139:3003/api";
}

import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
  position: "top",
  maxToasts: 3,
});

export default defineComponent({
  name: "ContactUs",
  data() {
    return {
      contents: {
        email: "",
        name: "",
        subject: "",
        message: "",
      },
      messageLength: 0,
      buttonLocked: true,
    };
  },
  methods: {
    async signUp() {
      if (this.buttonLocked) return;
      const email = this.contents.email;
      await axios({
        method: "post",
        url: "/contact/message",
        data: {
          name: this.contents.name,
          email: email,
          subject: this.contents.subject,
          message: this.contents.message,
          date: new Date().toISOString(),
        },
      })
        .then((response: any) => {
          if (response.status === 200) {
            console.log("Signed up for newsletter!");
            this.contents.email = "";
            this.contents.name = "";
            this.contents.message = "";
            this.contents.subject = "";
            this.checkValidInput();
            toaster.success(
              "Your message has been received, we will get back to you as soon as possible.",
              {
                duration: 10000,
              }
            );
          } else {
            console.log("Message failed to send.");
            toaster.error("Sorry, something went wrong. Please try again.");
          }
        })
        .catch((error: any) => {
          console.log(error);
          toaster.error("Sorry, something went wrong. Please try again.");
        });
    },
    checkValidInput() {
      // I am lazy and don't want to make a new function
      this.messageLength = this.contents.message.length;

      const email = this.contents.email;
      const regex = /\S+@\S+\.\S+/;
      if (
        regex.test(email) &&
        this.contents.name.length > 0 &&
        this.contents.message.length > 0 &&
        this.contents.subject.length > 0
      ) {
        const button = document.getElementById("signup-button")!;
        button.classList.remove("locked-button");
        this.buttonLocked = false;
      } else {
        const button = document.getElementById("signup-button")!;
        button.classList.add("locked-button");
        this.buttonLocked = true;
      }
    },
  },
});
